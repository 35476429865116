<template>
	<section id="hero">
		<v-row no-gutters>
			<v-img
				:min-height="`calc(100vh - ${$vuetify.application.top}px)`"
				src="@/assets/bg-image1.jpg"
			>
				<v-container fill-height>
					<v-row align="center" class="mx-auto mb-16" justify="center">
						<v-col class="white--text text-center" cols="12" tag="h1">
							<span
								:class="[
									$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4',
								]"
								class="text-shadow font-weight-black"
							>
								PetVet Manager
							</span>

							<div class="divider"></div>

							<span
								:class="[
									$vuetify.breakpoint.smAndDown ? 'display-0' : 'display-1',
								]"
								class="text-shadow font-weight-medium"
							>
								{{ $t('welcomeTo') }}
							</span>
						</v-col>

						<v-btn
							class="align-self-end"
							color="secondary"
							fab
							@click="$vuetify.goTo('#app-features')"
						>
							<v-icon color="white">mdi-chevron-double-down</v-icon>
						</v-btn>
					</v-row>
				</v-container>
			</v-img>
		</v-row>
	</section>
</template>

<script>
export default {
	name: 'AppWelcome',
};
</script>

<style lang="scss" scoped>
.divider {
	height: 8px;
}
</style>
